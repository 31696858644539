import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { graphql, Link, useStaticQuery } from "gatsby"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { Layout } from "../components/layout"
import { formatDate } from "../components/formatDate"
import { DividerBlock } from "../components/dividerBlock"
import ImageFadeIn from "react-image-fade-in"

const News = () => {
  const data = useStaticQuery(graphql`
    query NewsQuery {
      datoCmsNews {
        seoMetaTags {
          ...GatsbyDatoCmsSeoMetaTags
        }
        accentColor {
          hex
        }
        backgroundColor
        title
      }
      allDatoCmsSingleNews(
        sort: { fields: [datePublished], order: DESC }
        filter: { meta: { isValid: { eq: true }, status: { ne: "draft" } } }
      ) {
        edges {
          node {
            formattedTitle
            slug
            id
            featuredImage {
              alt
							url(imgixParams: { w: "600", ar: "5:3", fit: "crop", crop: "focalpoint" })
            }
            excerpt
            datePublished
          }
        }
      }
    }
  `)
  const news = data.datoCmsNews
  const allNews = data.allDatoCmsSingleNews.edges
  const [list, setList] = useState([...allNews.slice(0, 10)])
  const [loadMore, setLoadMore] = useState(false)
  const [hasMore, setHasMore] = useState(allNews.length > 10)

  const handleLoadMore = () => {
    setLoadMore(true)
  }

  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length
      const isMore = currentLength < allNews.length
      const nextResults = isMore
        ? allNews.slice(currentLength, currentLength + 10)
        : []
      setList([...list, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore])

  useEffect(() => {
    const isMore = list.length < allNews.length
    setHasMore(isMore)
  }, [list])

  return (
    <Layout
      bgcolor={news.backgroundColor}
      wrapperClass="news"
      accentColor={news.accentColor}
    >
      <HelmetDatoCms seo={news.seoMetaTags} />
      <div className="news-intro container row">
        <div className="col-sm-7 offset-sm-2 col-xs-6 col">
          <h1 className="gothic--large large-title">{news.title}</h1>
        </div>
      </div>
      <ul className="news-content container">
        {list.length > 0 &&
          list.map(({ node: article }) => {
            const niceDate = formatDate(article.datePublished)
            return (
              <li className="news-item row reverse" key={article.id}>
                <div className="news-item-image col-sm-4 offset-sm-1 col-xs-6 col">
                  <Link to={`/news/${article.slug}`}>
										<ImageFadeIn
												src={article.featuredImage.url}
												alt={article.featuredImage.alt}
											/>
                  </Link>
                </div>
                <div className="news-item-content col-sm-7 offset-sm-2 col-xs-6 col">
                  <Link to={`/news/${article.slug}`}>
                    <h2
                      className="gothic--regular"
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: article.formattedTitle,
                      }}
                    />
                  </Link>
                  <div
                    className="excerpt content"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: article.excerpt,
                    }}
                  />
                  <Link className="more-link" to={`/news/${article.slug}`}>
                    Read more
                  </Link>
                  <p className="posted">{`Posted on ${niceDate.date} ${niceDate.monthLong} ${niceDate.year}`}</p>
                </div>
              </li>
            )
          })}
      </ul>
      <div className="container row">
        {hasMore ? (
          <div className="offset-sm-2 col-sm-12 col-xs-6 col">
            <button
              onClick={handleLoadMore}
              type="button"
              aria-label="Load more"
              className="btn show-more"
            >
              Load more
            </button>
          </div>
        ) : (
          <div className="offset-sm-2 col-sm-12 col-xs-6 col" />
        )}
      </div>
      <div className="container row">
        <div className="col-sm-5 col-xs-4 offset-xs-2 offset-sm-3 col">
          <DividerBlock accentColor={news.accentColor} />
        </div>
      </div>
    </Layout>
  )
}

News.propTypes = {
  data: PropTypes.objectOf(PropTypes.shape()),
}

export default News
